import AppLayout from '@views/AppLayout.vue'
import Error404Page from '@views/pages/Error404Page.vue'
import LoginPage from '@views/pages/LoginPage.vue'
import HomePage from '@views/pages/HomePage.vue'

export function createRoutes() {
  return [
    {
      name: 'root',
      path: '/',
      redirect: '/home',
      component: AppLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          name: 'home',
          path: 'home',
          component: HomePage,
        },

        // Administrator routes
        {
          name: 'administrator.list',
          path: 'administrators',
          component: () =>
            import(
              /* webpackChunkName: "administrator" */ '@views/pages/ListAdministratorPage.vue'
            ),
        },
        {
          name: 'administrator.edit',
          path: 'administrator/:id',
          component: () =>
            import(
              /* webpackChunkName: "administrator" */ '@views/pages/EditAdministratorPage/index.vue'
            ),
          props: true,
        },

        // Spot tracker routes
        {
          name: 'spotTracker.list',
          path: 'spot-trackers',
          component: () =>
            import(
              /* webpackChunkName: "spot_tracker" */ '@views/pages/ListSpotTrackersPage/index.vue'
            ),
        },
        {
          name: 'spotTracker.add',
          path: 'spot-tracker/add',
          component: () =>
            import(
              /* webpackChunkName: "spot_tracker" */ '@views/pages/AddSpotTrackerPage/index.vue'
            ),
        },
        {
          name: 'spotTracker.edit',
          path: 'spot-tracker/:id',
          component: () =>
            import(
              /* webpackChunkName: "spot_tracker" */ '@views/pages/EditSpotTrackerPage/index.vue'
            ),
          props: true,
        },

        // Queclink tracker routes
        {
          name: 'queclinkTracker.list',
          path: 'queclink-trackers',
          component: () =>
            import(
              /* webpackChunkName: "queclink_tracker" */ '@views/pages/ListQueclinkTrackersPage/index.vue'
            ),
        },
        {
          name: 'queclinkTracker.add',
          path: 'queclink-tracker/add',
          component: () =>
            import(
              /* webpackChunkName: "queclink_tracker" */ '@views/pages/AddQueclinkTrackerPage/index.vue'
            ),
        },
        {
          name: 'queclinkTracker.edit',
          path: 'queclink-tracker/:id',
          component: () =>
            import(
              /* webpackChunkName: "queclink_tracker" */ '@views/pages/EditQueclinkTrackerPage/index.vue'
            ),
          props: true,
        },

        // Live routes
        {
          name: 'live.list',
          path: 'lives',
          component: () =>
            import(/* webpackChunkName: "live" */ '@views/pages/ListLivesPage/index.vue'),
        },
        {
          name: 'live.add',
          path: 'live/add',
          component: () =>
            import(/* webpackChunkName: "live" */ '@views/pages/AddLivePage/index.vue'),
        },
        {
          name: 'live.setup',
          path: 'live/setup',
          component: () =>
            import(/* webpackChunkName: "live" */ '@views/pages/LiveSetupFormPage/index.vue'),
        },
        {
          path: 'live/:id',
          component: () =>
            import(/* webpackChunkName: "live" */ '@views/pages/EditLivePage/index.vue'),
          props: true,
          children: [
            {
              name: 'live.edit.informations',
              path: '',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveInformationsTab.vue'
                ),
            },
            {
              name: 'live.edit.checklist',
              path: 'checklist',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveChecklistItemTab/index.vue'
                ),
            },
            {
              name: 'live.edit.category.list',
              path: 'categories',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveCategoriesTab/index.vue'
                ),
            },
            {
              name: 'live.edit.category.add',
              path: 'category/add',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveAddCategoryTab.vue'
                ),
            },
            {
              name: 'live.edit.category.edit',
              path: 'category/:liveCategoryId',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveEditCategoryTab.vue'
                ),
              props: true,
            },
            {
              name: 'live.edit.vehicle.list',
              path: 'vehicles',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveVehiclesTab/index.vue'
                ),
              props: true,
            },
            {
              name: 'live.edit.vehicle.add',
              path: 'vehicle/add',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveAddVehicleTab/index.vue'
                ),
            },
            {
              name: 'live.edit.vehicle.edit',
              path: 'vehicle/:liveVehicleId',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveEditVehicleTab/index.vue'
                ),
              props: true,
            },
            {
              name: 'live.edit.stage.list',
              path: 'stages',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveStagesTab/index.vue'
                ),
            },
            {
              name: 'live.edit.stage.add',
              path: 'stage/add',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveAddStageTab.vue'
                ),
            },
            {
              name: 'live.edit.stage.edit',
              path: 'stage/:liveStageId',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveEditStageTab.vue'
                ),
              props: true,
            },
            {
              name: 'live.edit.stage.edit.waypoint.add',
              path: 'stage/:liveStageId/waypoint/add',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveAddWaypointTab/index.vue'
                ),
              props: true,
            },
            {
              name: 'live.edit.stage.edit.speedZone.add',
              path: 'stage/:liveStageId/speed-zone/add',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveAddSpeedZoneTab/index.vue'
                ),
              props: true,
            },
            {
              name: 'live.edit.settings.edit',
              path: 'settings',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveEditSettingsTab.vue'
                ),
              props: true,
            },
            {
              name: 'live.edit.alertContact.add',
              path: 'alert-contact/add',
              component: () =>
                import(
                  /* webpackChunkName: "live" */ '@views/pages/EditLivePage/EditLiveAddAlertContact'
                ),
              props: true,
            },
          ],
        },

        // Live tutorial routes
        {
          name: 'tutorial.list',
          path: 'tutorials',
          component: () =>
            import(/* webpackChunkName: "live" */ '@views/pages/ListLiveTutorialsPage/index.vue'),
        },
        {
          name: 'tutorial.add',
          path: 'tutorial/add',
          component: () =>
            import(/* webpackChunkName: "live" */ '@views/pages/AddLiveTutorialPage'),
        },
      ],
    },

    // General routes
    {
      name: 'login',
      path: '/login',
      component: LoginPage,
    },
    {
      name: '404',
      path: '*',
      component: Error404Page,
    },
  ]
}
