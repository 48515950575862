export class LiveStageWaypointService {
  constructor(api) {
    this.api = api
  }

  async getAll(liveStageId) {
    return (await this.api.get(`/live_stages/${liveStageId}/waypoints`)).map(this.parse)
  }

  async replace(liveStageId, file) {
    const data = new FormData()
    data.append('file', file)
    return await this.api.post(`/live_stages/${liveStageId}/waypoints`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async create({
    liveStageId,
    name,
    icon,
    color,
    accessType,
    location,
    radius,
    role,
    label,
    liveCategoryId,
    enabledAt,
  }) {
    return this.parse(
      await this.api.post('live_stage_waypoints', {
        liveStageId,
        name,
        icon,
        color,
        accessType,
        location,
        radius,
        role,
        label,
        liveCategoryId,
        enabledAt,
      }),
    )
  }

  async update(
    id,
    { name, icon, color, accessType, position, radius, role, label, liveCategoryId, enabledAt },
  ) {
    return this.parse(
      await this.api.put(`/live_stage_waypoints/${id}`, {
        name,
        icon,
        color,
        accessType,
        position,
        radius,
        role,
        label,
        liveCategoryId,
        enabledAt,
      }),
    )
  }

  async updateAll({ liveStageId, waypoints }) {
    return (await this.api.put(`/live_stages/${liveStageId}/waypoints`, { waypoints })).map(
      this.parse,
    )
  }

  async delete(id) {
    return await this.api.delete(`/live_stage_waypoints/${id}`)
  }

  async deleteAll({ waypointIds }) {
    return await this.api.delete('live_stage_waypoints', { waypointIds })
  }

  async exportGpx({ liveStageId }) {
    return await this.api.get(`live_stages/${liveStageId}/waypoints/export`)
  }

  async generateStartFinish(liveStagePathId) {
    return (
      await this.api.post(`live_stage_waypoints/generate_from_stage_path`, { liveStagePathId })
    ).map(this.parse)
  }

  parse(waypoint) {
    if (waypoint.enabledAt) {
      waypoint.enabledAt = new Date(waypoint.enabledAt)
    }

    return waypoint
  }
}
