export class LiveSettingsService {
  constructor(api) {
    this.api = api
  }

  async getByLiveId(liveId) {
    return await this.api.get(`/lives/${liveId}/settings`)
  }

  async update(
    id,
    {
      coordinateFormat,
      defaultPublicMapLayer,
      defaultPrivateMapLayer,
      showStageGeographyBeforeStart,
      publicMapMinZoom,
      publicMapMaxZoom,
      alertStatuses,
      showSwitchTrackers,
      showPublicCheckpoints,
      socialFeedId,
      vehicleInactivityTimeout,
    },
  ) {
    return await this.api.put(`/live_settings/${id}`, {
      id,
      coordinateFormat,
      defaultPublicMapLayer,
      defaultPrivateMapLayer,
      showStageGeographyBeforeStart,
      publicMapMinZoom,
      publicMapMaxZoom,
      alertStatuses,
      showSwitchTrackers,
      showPublicCheckpoints,
      socialFeedId,
      vehicleInactivityTimeout,
    })
  }
}
